const queryTools = {
	resolveSiteMetadataEdge: function(field, data) {
		if (field == "title") {
			console.log(data.allSite.edges[0].node.siteMetadata)
		}
		return data.allSite.edges[0].node.siteMetadata[field]
	},
}

export default queryTools
