import React from "react"
// import styles from "../styles/glitchFrenchWave.module.css"
import styles from "../styles/strongSlant.module.css"

export default ({ boxTitle, children }) => (
	<div className={styles.factBox}>
		<h5>{boxTitle}</h5>

		<div className={styles.factBoxInner}>{children}</div>
	</div>
)
